<template>
  <google-reviews-summary-card/>
</template>

<script>
import GoogleReviewsSummaryCard from '@/views/_components/GoogleReviewsSummaryCard.vue';

/**
 * Review analytics
 *
 * @module views/analytics/ReviewsAnalytics
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'ReviewsAnalytics',
  components: {
    GoogleReviewsSummaryCard,
  },
};
</script>

<style scoped>

</style>
